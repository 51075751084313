<template>
    <div>
        <b-card>
            <app-view-table :title="trans('prospect',5)" :loading="loading_list" :isTitleTotal="true" :filters.sync="filters" :items="items" v-on:onRowSelected="getProfile" :fields="fields" :canUserEdit="user_role < 3" :filterOptions="prospect_status_list" :isSearch="true" :isFilterOptions="true" class="table-selectable">
                
                <template v-slot:menu-items="cell">
                    <b-dropdown-item @click="changeToSuspect(cell.data.item)" >{{trans('change-to-suspect',189)}}</b-dropdown-item>
                </template>
                
            </app-view-table>

            
        </b-card> 
    </div>
</template>
<script>

import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';
import UserSettings from '@core/scripts/UserSettings';

export default {
    components: {
        
    },

    mounted() {
        if(this.$route.params.status != null){
            this.changeFilters(this.$route.params.status);
        }else{
            this.onCreateThis();
        }
    },

    data() {
        return {
            loading_list: false,
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                filtering: {  
                    locations: {type: 'list',  changed: 0, values: [], function: "getLocations", parameters: null, widthClass:'app-width-min-250'},
                    statuses: {type: 'list', changed: 0, values: [], function: "getClientStatuses", parameters: null, widthClass:'app-width-min-250'},                      
                    
                },
                page: 1,
                per_page: 10,
                search: "",
                list_type: 1,
                changed: 0
            },
            fields: [
                { key: "client_name", label: this.trans('organization',169), thStyle: { "min-width": "130px", width: "100%" }, visible: true},
                { key: "location_name", label: this.trans('branch',182), thStyle: { "min-width": "150px", "max-width": "200px" }, visible: true, filtering: true, filtering_name: "locations"  },
                { key: "status_name", label: this.trans('status',3), thStyle: { "min-width": "200px", "max-width": "300px" }, visible: true, filtering: true, filtering_name: "statuses"  }, 
            ],
            items: [],
            prospect_status_list: [
                { value: 1, name: this.trans('my-locations',179) },
                { value: 2, name: this.trans('my-prospects',3) },               
                { value: -1, name: ""},
                { value: 5, name: this.trans('all-prospects',187) },
            ],
            alertClass: new AppAlerts(),
            userSettings: new UserSettings(),
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,

        };
    },

    methods: {
        getList: function() {
            
            this.loading_list = true;
            axios
                .post("clients/prospect/getList", {
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.filters.page = res.data.page;
                    this.items = res.data.items;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                }).finally(() => {
                    this.loading_list = false;
                });
        },

        getProfile: function(selectedRows) {
            if(selectedRows.length != 0){
                this.$router.push({ name: "clients-profile-organization", params: { id_client_data_main: selectedRows[0].id_client_data_main } });
            }
        },

        changeFilters(status){
            this.filters.filtering.statuses.values = [status];
            this.getList();
        },

        saveSettings() {
            this.userSettings.saveSettings(this.filters, 3);
        },

        async loadSettings() {
            var getData = JSON.parse(localStorage.getItem('userSettings'));

            if(getData == undefined || getData.prospectList == undefined){
                await this.userSettings.saveSettings(this.filters, 3);
                getData = JSON.parse(await localStorage.getItem('userSettings'));
            }
                
            var sFilters = getData.prospectList;

            this.filters.list_type = sFilters.list_type;
            this.filters.filtering = sFilters.filtering;
            this.filters.sorting = sFilters.sorting;     
            this.filters.per_page = sFilters.per_page != undefined ? sFilters.per_page : 10;  
        },

        rejectProspect: function( data ) {
            if(data.status_name == "Ingetrokken"){
                this.alertClass.openAlertInfo(this.trans('applicant-no-suitable-status-error',180)).then(res => {
                }); 
            }else{
                this.alertClass.openAlertConfirmation(this.trans('revoke-prospect-confirmation', 191, {client_name : data.client_name})).then(res => {
                    if(res == true){
                        this.loading_list = true;
                        axios
                            .post("clients/prospect/reject", {
                                id_client_data_main: data.id_client_data_main,
                                filters: JSON.stringify(this.filters),
                            })
                            .then((res) => {
                                this.filters.page = res.data.page
                                this.items = res.data.items;
                            })
                            .catch((error) => {
                                console.error(`error during request: ${error}`);
                            })
                                .finally(() => {
                                this.loading_list = false;
                            });;
                    }     
                }); 
            }
        },
        deleteProspect: function( data ) {
            if(data.status_name == "Afgewezen"){
                this.alertClass.openAlertInfo(this.trans('applicant-no-suitable-status-error', 180)).then(res => {
                }); 
            }else{
                this.alertClass.openAlertConfirmation(this.trans('reject-prospect-confirmation', 191, {client_name : data.client_name})).then(res => {
                    if(res == true){
                        this.loading_list = true;
                        axios
                            .post("clients/prospect/delete", {
                                filters: JSON.stringify(this.filters),
                                id_client_data_main: data.id_client_data_main,
                            })
                            .then((res) => {
                                this.filters.page = res.data.page
                                this.items = res.data.items;
                            })
                            .catch((error) => {
                                console.error(`error during request: ${error}`);
                            }).finally(() => {
                                this.loading_list = false;
                            });;
                    }     
                }); 
            }
        },

        changeToSuspect(item){
            this.alertClass.openAlertConfirmation(this.trans('confirm-message',180)).then(res => {
                if(res == true){
                    this.loading_list = true;
                    axios
                        .post('clients/prospect/changeStatusToSuspect',{
                            id_client_data_main: item.id_client_data_main,
                            filters: JSON.stringify(this.filters)
                        })
                        .then((res) => {
                            this.filters.page = res.data.page
                            this.items = res.data.items;
                        })
                        .catch((error) => {
                            console.error(`error ${error}`);
                        })
                        .finally(() => {
                            this.loading_list = false;
                        })

                }
            });
        },

        onCreateThis() { 
            this.loadSettings();

            this.getList();

            this.$root.$on('locationsChanged', () => {
        
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.list_type', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('filters.changed',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.filters.page = 1;
                    this.getList();
                } 
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });
            this.$watch('filters.filtering.statuses.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('filters.filtering.locations.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings()
                } 
            });
        },

    },

    

    
};
</script>